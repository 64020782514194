const loadFileBlock = (editor) => {
    editor.BlockManager.add('CtaFileBlock', {
      label: 'Downloadable File Block',
      content: `
        <section class="cta-file-block" style="padding: 50px 20px; background: #28a745; color: white; text-align: center; border-radius: 8px;">
          <h3 style="font-size: 2em; margin-bottom: 20px;">Download Our File</h3>
          <p style="font-size: 1.2em;">Click below to download the file</p>
          <a href="#" download class="cta-file-button" style="display: inline-block; padding: 10px 20px; background: #fff; color: #28a745; border: none; border-radius: 5px; cursor: pointer; text-decoration: none; font-weight: bold; margin-top: 20px;">
            Download File
          </a>
        </section>
      `,
      category: 'Basic',
    });
  
    editor.DomComponents.addType('cta-file-block', {
      model: {
        defaults: {
          tagName: 'section',
          classes: ['cta-file-block'],
          components: [
            {
              tagName: 'h3',
              content: 'Download Our File',
              attributes: { style: 'font-size: 2em; margin-bottom: 20px;' },
            },
            {
              tagName: 'p',
              content: 'Click below to download the file',
              attributes: { style: 'font-size: 1.2em;' },
            },
            {
              tagName: 'a',
              content: 'Download File',
              attributes: {
                href: '#',
                download: '', // El atributo que indica que el enlace es descargable
                class: 'cta-file-button',
                style:
                  'display: inline-block; padding: 10px 20px; background: #fff; color: #28a745; border: none; border-radius: 5px; cursor: pointer; text-decoration: none; font-weight: bold; margin-top: 20px;',
              },
              traits: [
                {
                  type: 'text',
                  name: 'href',
                  label: 'File URL',
                  placeholder: 'Enter file URL here',
                },
                {
                  type: 'text',
                  name: 'content',
                  label: 'Button Text',
                  placeholder: 'Download File',
                },
                {
                  type: 'checkbox', // Trait para activar o desactivar el atributo download
                  name: 'download',
                  label: 'Enable Download',
                  valueTrue: '', // Dejar vacío significa que el atributo se activa sin valor
                  valueFalse: false, // Elimina el atributo si está desactivado
                },
              ],
            },
          ],
        },
      },
    });
  };
  
  export default loadFileBlock;
  