const loadThreeImageCards = (editor) => {
    editor.BlockManager.add('three-image-cards', {
      label: 'Tarjetas de Tres Imágenes',
      content: `
        <section class="three-cards-section">
          <div class="three-cards-container">
            <div class="card">
              <div class="card-image">
                <img src="https://www.maestriaennegociosdigitales.com/imagenes/teamrendimiento.jpeg" alt="Imagen 1" class="responsive-card-image">
              </div>
              <div class="card-text">
                <h3 class="card-title">Formación y Gestión de Equipos de Alto Rendimiento</h3>
                <a href="#i" class="card-cta">Más Información</a>
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img src="https://www.maestriaennegociosdigitales.com/imagenes/sostenible.jpeg" alt="Imagen 2" class="responsive-card-image">
              </div>
              <div class="card-text">
                <h3 class="card-title">Fundamentos del Emprendimiento Sostenible</h3>
                <a href="#" class="card-cta">Más Información</a>
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img src="https://www.maestriaennegociosdigitales.com/imagenes/mundodigital.jpeg" alt="Imagen 3" class="responsive-card-image">
              </div>
              <div class="card-text">
                <h3 class="card-title">Globalización y Negocios Internacionales</h3>
                <a href="#" class="card-cta">Más Información</a>
              </div>
            </div>
          </div>
        </section>
        <style>
          .three-cards-section {
            padding: 40px 20px;
            background-color: #f8f9fa;
            font-family: 'Arial', sans-serif;
          }
          .three-cards-container {
            display: flex;
            justify-content: center;
            gap: 20px;
            flex-wrap: wrap;
            max-width: 1200px;
            margin: 0 auto;
          }
          .card {
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            max-width: 300px;
            text-align: center;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
          }
          .card:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
          }
          .card-image img {
            width: 100%;
            height: auto;
          }
          .card-title {
            font-size: 20px;
            font-weight: bold;
            color: #333;
            margin: 15px 0 10px;
          }
          .card-description {
            font-size: 16px;
            color: #555;
            margin-bottom: 15px;
            padding: 0 10px;
          }
          .card-cta {
            display: inline-block;
            background-color: #007bff;
            color: #fff;
            padding: 10px 20px;
            text-decoration: none;
            font-weight: bold;
            border-radius: 5px;
            transition: background-color 0.3s ease, transform 0.3s ease;
          }
          .card-cta:hover {
            background-color: #0056b3;
            transform: translateY(-3px);
          }
          /* Responsive Design */
          @media (max-width: 768px) {
            .three-cards-container {
              flex-direction: column;
              align-items: center;
            }
            .card {
              width: 100%;
              max-width: 350px;
            }
          }
        </style>
      `,
      category: 'Tarjetas',
      attributes: { class: 'gjs-block-three-cards' },
    });
  };
  
  export default loadThreeImageCards;
  