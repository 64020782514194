const loadEducationalOfferBlock = (editor) => {
    editor.BlockManager.add('Educational Offer Block', {
      label: 'Oferta Educativa',
      content: `
        <div class="educational-offer-block" style="padding: 50px 20px; background-color: #f9f9f9; text-align: center; font-family: 'Poppins', sans-serif; color: #333;">
          <h2 style="font-size: 2.5rem; margin-bottom: 20px; color: #007BFF;">Conoce nuestra oferta educativa</h2>
          <p style="font-size: 1.2rem; margin-bottom: 40px; line-height: 1.6;">
            En la era de la transformación digital, la <strong>Maestría en Negocios Digitales</strong> se presenta como una oportunidad única para adquirir las habilidades y conocimientos necesarios para liderar en el dinámico mundo empresarial actual. Este programa está diseñado para profesionales que buscan estar a la vanguardia de las tecnologías emergentes y las estrategias digitales.
          </p>
          <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); gap: 20px; text-align: left;">
            <div style="padding: 20px; background: #fff; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
              <h3 style="font-size: 1.5rem; margin-bottom: 10px; color: #007BFF;">Teoría y práctica</h3>
              <p style="font-size: 1rem; line-height: 1.6; color: #666;">
                Nuestra maestría ofrece una combinación de teoría y práctica, brindando una comprensión profunda de la gestión de proyectos digitales.
              </p>
            </div>
            <div style="padding: 20px; background: #fff; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
              <h3 style="font-size: 1.5rem; margin-bottom: 10px; color: #007BFF;">Plan de estudios actualizado</h3>
              <p style="font-size: 1rem; line-height: 1.6; color: #666;">
                Los estudiantes aprenderán a desarrollar e implementar estrategias digitales efectivas, gestionar equipos y proyectos, y utilizar herramientas analíticas.
              </p>
            </div>
            <div style="padding: 20px; background: #fff; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
              <h3 style="font-size: 1.5rem; margin-bottom: 10px; color: #007BFF;">Habilidades en demanda</h3>
              <p style="font-size: 1rem; line-height: 1.6; color: #666;">
                Prepárate para liderar en el marketing digital, la analítica de datos y la innovación tecnológica con habilidades alineadas a las demandas actuales.
              </p>
            </div>
          </div>
          <a href="#learn-more" style="display: inline-block; margin-top: 40px; padding: 10px 20px; background-color: #007BFF; color: #fff; text-decoration: none; border-radius: 5px; font-size: 1.1rem;">
            Aprende más sobre la maestría
          </a>
        </div>
      `,
      category: 'Index',
      attributes: { class: 'gjs-block-educational-offer' },
    });
  };
  
  export default loadEducationalOfferBlock;
  