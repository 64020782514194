const loadBannerBlock = (editor) => {
    editor.BlockManager.add('Banner Block', {
      label: 'Banner Index', 
      content: `
         <div class="banner-block" style="position: relative; background-image: url('https://www.maestriaennegociosdigitales.com/static/media/imagenportada.f10494f218a4ac505595.jpeg'); background-size: cover; background-position: center; padding: 50px 20px; text-align: center; color: #fff;">
        <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); z-index: 1;"></div>
        <div style="position: relative; z-index: 2;">
          <h1 style="font-size: 3rem; margin-bottom: 20px;">Maestría en Negocios Digitales</h1>
          <p style="font-size: 1.2rem; margin-bottom: 30px;">¡CONVIERTETE EN UN EXPERTO EN EL MUNDO DIGITAL!</p>
          <a href="#services" style="padding: 10px 20px; background-color: #007BFF; color: #fff; text-decoration: none; border-radius: 5px;">Descubre Más</a>
        </div>
      </div>
    `, 
      category: 'Index',
      attributes: { class: 'gjs-block-banner' }, 
    });
  };
  
  export default loadBannerBlock;
  