import React, { useState } from 'react';
import Navbar from '../components/Admin/NavBar'; // Componente de cabecera.
import { db } from '../api/firebase.config'; // Configuración de Firebase Firestore.
import { collection, addDoc } from 'firebase/firestore'; // Métodos necesarios para Firestore.

export const Nuecont = () => {
  // Valores iniciales del formulario.
  const valorInicial = {
    nombre: '',
    edad: '',
    genero: '',
    correo: '',
    numero: '',
    ocupacion: '',
  };

  // Estado para manejar los valores del formulario.
  const [user, setUser] = useState(valorInicial);

  // Capturar valores de los inputs del formulario.
  const capturarInputs = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  // Función para guardar los datos en Firestore.
  const guardarDatos = async (e) => {
    e.preventDefault(); // Evita el comportamiento predeterminado del formulario.
    const datosConvertidos = {
      ...user,
      edad: parseInt(user.edad, 10), // Convierte edad a número entero.
      numero: parseInt(user.numero, 10), // Convierte número a número entero.
    };

    try {
      await addDoc(collection(db, 'contactos'), datosConvertidos); // Agrega el contacto a Firestore.
      alert('Contacto agregado exitosamente');
    } catch (error) {
      console.error('Error al guardar los datos:', error);
    }

    // Resetea el formulario.
    setUser({ ...valorInicial });
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar/> {/* Componente de cabecera */}

      <div className="flex-grow bg-gray-100 py-10">
        <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg p-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Agregar Nuevo Contacto </h2>
          <form onSubmit={guardarDatos} className="space-y-4">
           
            {/* Campo para el nombre */}
            <div>
              <label htmlFor="nombrec" className="block text-gray-700 font-medium mb-2">Nombre:</label>
              <input
                id="nombrec" name="nombre" type="text"
                className="w-full border border-gray-300 rounded-md shadow-sm px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
                onChange={capturarInputs}value={user.nombre}/>
            </div>

            {/* Agrupar edad y género en la misma línea */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="edad" className="block text-gray-700 font-medium mb-2">
                  Edad:
                </label>
                <input
                  id="edad" name="edad" type="number"
                  className="w-full border border-gray-300 rounded-md shadow-sm px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
                  onChange={capturarInputs} value={user.edad} />
              </div>
              
              <div>
                <label htmlFor="genero" className="block text-gray-700 font-medium mb-2">Género:</label>
                <select
                  id="genero" name="genero"
                  className="w-full border border-gray-300 rounded-md shadow-sm px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
                  onChange={capturarInputs}
                  value={user.genero}>
                  <option value="">Seleccione una opción</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Otros">Otros</option>
                </select>
              </div>
            </div>

            {/* Campo para el correo */}
            <div>
              <label htmlFor="correoc" className="block text-gray-700 font-medium mb-2">Correo:</label>
              <input
                id="correoc" name="correo" type="email"
                className="w-full border border-gray-300 rounded-md shadow-sm px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
                onChange={capturarInputs}value={user.correo}/>
            </div>

            {/* Campo para el número telefónico */}
            <div>
              <label htmlFor="numeroc" className="block text-gray-700 font-medium mb-2">Número telefónico:</label>
              <input
                id="numeroc"name="numero" type="number"
                className="w-full border border-gray-300 rounded-md shadow-sm px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
                onChange={capturarInputs} value={user.numero}/>
            </div>

            {/* Campo para la ocupación */}
            <div>
              <label htmlFor="ocupacion" className="block text-gray-700 font-medium mb-2">Ocupación:</label>
              <input
                id="ocupacion" name="ocupacion" type="text"
                className="w-full border border-gray-300 rounded-md shadow-sm px-4 py-2 focus:ring-blue-500 focus:border-blue-500"
                onChange={capturarInputs} value={user.ocupacion}/>
            </div>

            {/* Botones de acción */}
            <div className="flex space-x-4">
              <button type="submit"
                className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 transition-colors duration-300">
                Guardar
                </button>

              <button
                type="button"
                className="bg-red-600 text-white px-3 py-1 rounded-md font-bold hover:bg-red-700 transition"
                onClick={() => setUser({ ...valorInicial })}> Limpiar 
                </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Nuecont;
