const loadAdvantagesBlock = (editor) => {
    editor.BlockManager.add('Advantages Block', {
      label: 'Nuestras Ventajas',
      content: `
        <div class="advantages-block" style="padding: 50px 20px; text-align: center; background-color: #f9f9f9;">
          <h2 style="font-size: 2.5rem; margin-bottom: 40px; font-family: 'Poppins', sans-serif; color: #333;">Nuestras Ventajas</h2>
          <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); gap: 20px;">
            <div style="padding: 20px; background: #fff; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
              <div style="font-size: 3rem; color: #007BFF; margin-bottom: 15px;">💡</div>
              <h3 style="font-size: 1.5rem; margin-bottom: 10px; color: #333; font-family: 'Poppins', sans-serif;">Innovación</h3>
              <p style="font-size: 1rem; color: #666;">Ofrecemos soluciones creativas y modernas que se adaptan a tus necesidades.</p>
            </div>
            <div style="padding: 20px; background: #fff; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
              <div style="font-size: 3rem; color: #007BFF; margin-bottom: 15px;">⚡</div>
              <h3 style="font-size: 1.5rem; margin-bottom: 10px; color: #333; font-family: 'Poppins', sans-serif;">Eficiencia</h3>
              <p style="font-size: 1rem; color: #666;">Maximizamos resultados con el menor consumo de recursos posibles.</p>
            </div>
            <div style="padding: 20px; background: #fff; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
              <div style="font-size: 3rem; color: #007BFF; margin-bottom: 15px;">🌍</div>
              <h3 style="font-size: 1.5rem; margin-bottom: 10px; color: #333; font-family: 'Poppins', sans-serif;">Adaptación</h3>
              <p style="font-size: 1rem; color: #666;">Este programa te prepara para adaptarte rápidamente a los cambios tecnológicos y del mercado.</p>
            </div>
            <div style="padding: 20px; background: #fff; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
              <div style="font-size: 3rem; color: #007BFF; margin-bottom: 15px;">🤝</div>
              <h3 style="font-size: 1.5rem; margin-bottom: 10px; color: #333; font-family: 'Poppins', sans-serif;">Confianza</h3>
              <p style="font-size: 1rem; color: #666;">Establecemos relaciones sólidas basadas en transparencia y honestidad.</p>
            </div>
          </div>
        </div>
      `,
      category: 'Index',
      attributes: { class: 'gjs-block-advantages' },
    });
  };
  
  export default loadAdvantagesBlock;
  