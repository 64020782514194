import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage';
import { app } from '../../api/firebase.config';

const FileUploader = ({ setFileUrls }) => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // Obtener todos los archivos de Firebase al cargar el componente
  useEffect(() => {
    const fetchFiles = async () => {
      const storage = getStorage(app);
      const storageRef = ref(storage, 'uploads/');
      const files = await listAll(storageRef);
      
      const fileUrls = await Promise.all(
        files.items.map(async (fileRef) => {
          const url = await getDownloadURL(fileRef);
          return { name: fileRef.name, url };
        })
      );

      setUploadedFiles(fileUrls);
      setFileUrls(fileUrls); // Actualizamos los archivos en el componente principal
    };

    fetchFiles();
  }, [setFileUrls]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      alert('Por favor selecciona un archivo');
      return;
    }

    const storage = getStorage(app);
    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error('Error al subir el archivo:', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const newFile = { name: file.name, url: downloadURL };
          setUploadedFiles((prevFiles) => [...prevFiles, newFile]);
          setFileUrls((prevUrls) => [...prevUrls, newFile]); // Guardamos en el estado principal
          alert('Archivo subido exitosamente.');
        });
      }
    );
  };

  return (
    <div style={{ position: 'relative', maxWidth: '500px', margin: 'auto', textAlign: 'center' }}>
      <h3 style={{ fontFamily: 'Poppins, sans-serif' }}>Subir Archivo</h3>
      <input type="file" onChange={handleFileChange} style={{ padding: '10px', borderRadius: '5px', marginBottom: '10px' }} />
      <button 
        onClick={handleUpload} 
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          padding: '10px 20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Subir
      </button>
      {uploadProgress > 0 && <p>Progreso: {uploadProgress.toFixed(2)}%</p>}
    </div>
  );
};

export default FileUploader;
