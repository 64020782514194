import loadHeaderBlock from './HeaderBlock';
import loadBasicBlock from './BasicBlock';
import loadCtaBlock from './CtaBlock';
import loadFeaturesBlock from './FeaturesBlock';
import loadFooterBlock from './FooterBlock';
import loadIframeBlock from './IframeBlock';
import loadImageBlock from './ImageBlock';
import loadSectionBlock from './SectionBlock';
import loadTestimonialsBlock from './TestimonialsBlock';
import loadCodeBlock from './CodeBlock';
import loadPaypalBlock from './PaypalBlock';
import loadHeaderSection from './Sections/HeaderBlock';

// Importar bloques básicos
import loadButtonBlock from './Basics/ButtonBlock';
import loadVideoBlock from './Basics/VideoBlock';
import loadImageleft from './TextandImage/ImageLeft';

// Importar bloques de secciones
import loadContactBlock from './Sections/ContactBlock';
import loadAboutBlock from './Sections/AboutBlock';
import loadQuoteBlock from './Sections/QuoteBlock';
import loadContentBlock from './Sections/ContentBlock';
import loadIntroBlock from './Sections/IntroBlock';
import loadPricingBlock from './Sections/PricingBlock';
import loadPrivacyBlock from './Sections/PrivacyBlock';

// Importar bloque de infoproductos
import loadInfoProductBlock from './Infoproducts/Infoproducts'

// Bloques de imagenes y texto

import loadTitleImage from './TextandImage/TitleImage';
import loadImageRight from './TextandImage/ImageRight';

import loadPdfBlock from './loadPdfBlock';
import loadFileBlock from './loadFileBlock';

const loadBlogBlocks = (editor) => {
  loadHeaderBlock(editor);
  loadBasicBlock(editor);
  loadCtaBlock(editor);
  loadFeaturesBlock(editor);
  loadFooterBlock(editor);
  loadIframeBlock(editor);
  loadImageBlock(editor);
  loadSectionBlock(editor);
  loadTestimonialsBlock(editor);
  loadCodeBlock(editor);
  loadPaypalBlock(editor);
  loadHeaderSection(editor);

  // Cargar bloques básicos
  loadButtonBlock(editor);
  loadVideoBlock(editor);
  loadImageleft(editor);

  // Cargar bloques de secciones
  loadContactBlock(editor);
  loadAboutBlock(editor);
  loadQuoteBlock(editor);
  loadContentBlock(editor);
  loadIntroBlock(editor);
  loadPricingBlock(editor);
  loadPrivacyBlock(editor);

  // Cargar bloques de infoproductos
  loadInfoProductBlock(editor);

  // Cargar bloques de imagenes y texto
  loadTitleImage(editor);
  loadImageRight(editor);
  loadPdfBlock(editor);
  loadFileBlock(editor);
};

export default loadBlogBlocks;
