import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Index from '../components/Index';
import Footer from '../components/Footer';
import IndexView from '../components/Index/IndexView'

function Courses() {
  return (
    <div>
   

      <Header></Header>

      <IndexView></IndexView>
      
     
      <Footer></Footer>
    </div>
  );
}

export default Courses;
