import React, { useEffect, useState } from 'react';
import { getStorage, ref, listAll, getDownloadURL, deleteObject } from 'firebase/storage';

const FileViewer = () => {
  const [fileUrls, setFileUrls] = useState([]);
  const storage = getStorage();

  // Función para listar archivos en la carpeta "uploads"
  const fetchFiles = async () => {
    const uploadsRef = ref(storage, 'uploads');
    try {
      const result = await listAll(uploadsRef);
      const files = await Promise.all(
        result.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return { name: item.name, url, path: item.fullPath };
        })
      );
      setFileUrls(files);
    } catch (error) {
      console.error('Error al listar archivos:', error.message);
      alert('No se pudieron cargar los archivos. Verifica tus reglas de seguridad.');
    }
  };

  // Función para eliminar un archivo
  const handleDeleteFile = async (file) => {
    if (window.confirm(`¿Estás seguro de que deseas eliminar el archivo "${file.name}"?`)) {
      const fileRef = ref(storage, file.path);
      try {
        await deleteObject(fileRef);
        alert('Archivo eliminado correctamente.');
        setFileUrls((prevFiles) => prevFiles.filter((f) => f.path !== file.path));
      } catch (error) {
        console.error('Error al eliminar el archivo:', error.message);
        alert('No se pudo eliminar el archivo. Inténtalo nuevamente.');
      }
    }
  };

  // Copiar enlace al portapapeles
  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert('Enlace copiado al portapapeles');
    });
  };

  // Cargar archivos al montar el componente
  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <h3 style={{ fontFamily: 'Poppins, sans-serif', marginBottom: '20px' }}>Archivos Subidos</h3>
      {fileUrls.length === 0 ? (
        <p>No hay archivos subidos</p>
      ) : (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            gap: '20px',
          }}
        >
          {fileUrls.map((file, index) => (
            <div
              key={index}
              style={{
                border: '1px solid #ddd',
                borderRadius: '8px',
                padding: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                <img
                  src="https://via.placeholder.com/100"
                  alt="file-icon"
                  style={{
                    width: '100px',
                    height: '100px',
                    objectFit: 'contain',
                    borderRadius: '5px',
                  }}
                />
              </div>
              <p
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '14px',
                  marginBottom: '10px',
                }}
              >
                {file.name}
              </p>
              <a
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#007bff', textDecoration: 'none' }}
              >
                Ver archivo
              </a>
              <div style={{ marginTop: '10px' }}>
                <button
                  onClick={() => handleCopyLink(file.url)}
                  style={buttonStyle}
                >
                  Copiar enlace
                </button>
                <button
                  onClick={() => handleDeleteFile(file)}
                  style={{ ...buttonStyle, backgroundColor: '#dc3545', marginLeft: '10px' }}
                >
                  Eliminar
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const buttonStyle = {
  padding: '6px 12px',
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '14px',
};

export default FileViewer;
