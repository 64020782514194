import React, { useState } from 'react';
import FileUploader from '../../components/StorageFiles/FileUploader'
import FileViewer from '../../components/StorageFiles/Fileviewer'

function Uploader() {

    const [fileUrls, setFileUrls] = useState([]); 

  return (
    <>
     <FileUploader setFileUrls={setFileUrls} />
     <FileViewer />
  
  </>
  )
}

export default Uploader